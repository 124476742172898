import * as React from 'react';
import {
  Outlet,
  createFileRoute,
  Link, useLocation,
} from '@tanstack/react-router';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutline';
import NotificationsIcon from '@mui/icons-material/NotificationsNone';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import SettingsIcon from '@mui/icons-material/Settings';
import ErrorIcon from '@mui/icons-material/Error';
import { useAuth0 } from '@auth0/auth0-react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import LogoutIcon from '@mui/icons-material/Logout';
import { delay } from 'rambdax';
import CircularProgress from '@mui/material/CircularProgress';

import theme from '../theme';
import useBreakPoint from '../hooks/use-break-point';
import LanguageSelect from '../components/language-select/language-select';

type NavMenuItem = {
  text: string
  path: string
  icon: (props: any) => React.ReactNode
  selected?: boolean
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const DRAWER_WIDTH = 260;

export const Route = createFileRoute('/_auth')({
  component: AuthLayout,
});

function AuthLayout () {
  const { t } = useTranslation('routes');
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ isLoggingOut, setIsLoggingOut ] = React.useState<boolean>(false);
  const [ anchorElUser, setAnchorElUser ] = React.useState<null | Element>(null);
  const {
    logout,
    isLoading,
    user,
    error,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();
  const isScreenMedWidthOrSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation({
    select: (location) => location.pathname,
  });
  const { getBreakPointName } = useBreakPoint();
  React.useEffect(() => {
    if (isScreenMedWidthOrSmaller) {
      setOpen(false);
    }
  }, [ isScreenMedWidthOrSmaller, location ]);

  // This is necessary in conjunction with onRedirectCallback to allow
  React.useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { returnTo: window.location.origin },
      });
    };
    fn();
  }, [
    isLoading,
    isAuthenticated,
    loginWithRedirect,
  ]);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  const handleOpenUserMenu = (event: React.SyntheticEvent) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const navItems: NavMenuItem[] = [
    {
      text: t('home'),
      path: '/',
      icon: (props) => <HomeIcon {...props} />,
    },
    {
      text: t('accounts'),
      path: '/accounts',
      icon: (props) => <PeopleIcon {...props} />,
    },
    {
      text: t('notifications'),
      path: '/notifications',
      icon: (props) => <NotificationsIcon {...props} />,
    },
    {
      text: t('document_upload'),
      path: '/documents',
      icon: (props) => <ContentPasteSearchIcon {...props} />,
    },
    {
      text: t('admin_panel'),
      path: '/admin',
      icon: (props) => <SettingsIcon {...props} />,
    },
  ];
  const settingsItems = [
    {
      key: 'language_select',
      action: () => null,
      component: <LanguageSelect />,
    },
    {
      key: 'logout',
      text: t('logout_btn'),
      icon: (isLoggingOut) ? <CircularProgress size={20} /> : <LogoutIcon />,
      action: async () => {
        setIsLoggingOut(true);
        window.sessionStorage.clear();
        // since modifications to session storage are async, we need to wait a bit
        await delay(500);
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      },
    },
  ];

  const baseDrawerStyles = {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: (open) ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('md')]: {
      width: 0,
    },
    [theme.breakpoints.up('md')]: {
      width: (open) ? DRAWER_WIDTH : theme.spacing(8),
    },
  };

  if (!isAuthenticated && !isLoading) {
    return;
  }

  return (
    <>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Dialog
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        open={!!error}
      >
        <DialogTitle
          id='alert-dialog-title'
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ErrorIcon sx={{ mr: 1 }} />
          There was an error
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Typography>
              We were unable to authenticate you due to:
              {error?.message}
            </Typography>
            <Typography sx={{ mt: 2 }}>Click OK to log in again</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => loginWithRedirect()}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <MuiAppBar
          sx={{
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create([ 'margin', 'width' ], {
              easing: theme.transitions.easing.sharp,
              duration: (open) ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
            }),
            width: (open) ? `calc(100% - ${DRAWER_WIDTH}px)` : '100%',
            marginLeft: (open) ? `${DRAWER_WIDTH}px` : '0px',
            boxShadow: 'none',
            borderWidth: 0,
            borderStyle: 'solid',
            borderColor: 'rgba(0, 0, 0, 0.12)',
            borderBottomWidth: 'thin',
          }}
        >
          <Toolbar>
            <IconButton
              aria-label='open drawer'
              color='inherit'
              edge='start'
              sx={{
                marginRight: 0.5,
                [theme.breakpoints.up('md')]: {
                  marginRight: 2,
                },
                ...(open && { display: 'none' }),
              }}
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                flexGrow: 1,
                height: 40,
                [theme.breakpoints.up('md')]: {
                  height: 50,
                },
              }}
            >
              <img
                alt={t('logo_alt_text')}
                src={(getBreakPointName() === 'xs') ? 'https://media.creditpulse.com/branding/credit-pulse-icon-fullcolor.svg' : 'https://media.creditpulse.com/branding/credit-pulse-banner-logo-fullcolor.svg'}
                style={{
                  verticalAlign: 'middle',
                  height: '100%',
                }}
              />
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={t('avatar_tooltip')}>
                <IconButton
                  sx={{ p: 0 }}
                  onClick={handleOpenUserMenu}
                >
                  <Avatar
                    alt={user?.name}
                    src={user?.picture}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                keepMounted
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                id='menu-appbar'
                open={Boolean(anchorElUser)}
                sx={{ mt: '45px' }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={handleCloseUserMenu}
              >
                {settingsItems.map((setting) => (
                  <MenuItem
                    key={setting.key}
                    onClick={setting.action}
                  >
                    {setting.component}
                    {setting.icon}
                    <Typography sx={{
                      paddingLeft: 1,
                      textAlign: 'center',
                    }}
                    >
                      {setting.text}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </MuiAppBar>
        <MuiDrawer
          sx={{
            ...baseDrawerStyles,
            '& .MuiDrawer-paper': {
              ...baseDrawerStyles,
              [theme.breakpoints.down('md')]: {
                width: (open) ? DRAWER_WIDTH : 0,
              },
            },
          }}
          variant='permanent'
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {navItems.map(({
              text,
              path,
              icon,
            }) => (
              <Link
                key={text}
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
                to={path}
              >
                {({ isActive }) => (
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={isActive}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {icon({ color: (isActive) ? 'primary' : '' })}
                      </ListItemIcon>
                      <ListItemText
                        disableTypography={true}
                        primary={text}
                        sx={{
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              </Link>
            ))}
          </List>
        </MuiDrawer>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            m: 3,
          }}
        >
          <DrawerHeader />
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
