import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider,
  Link,
  createRouter,
  createHashHistory,
} from '@tanstack/react-router';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import './i18n';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

// Import the generated route tree
import { routeTree } from './routeTree.gen';

// Create a new router instance
const router = createRouter({
  routeTree,
  history: createHashHistory(),
  defaultNotFoundComponent: () => {
    return (
      <div>
        <p>Not found!</p>
        <Link to='/'>Go home</Link>
      </div>
    );
  },
  context: {
    auth: undefined!, // This will be set after we wrap the app in an AuthProvider
  },
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  // eslint-disable-next-line no-unused-vars
  interface Register {
    router: typeof router
  }
}

function App () {
  const auth = useAuth0();

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_BASE_URL,
  });
  const retryLink = new RetryLink();
  const removeTypenameLink = removeTypenameFromVariables();

  const authLink = setContext(async (_, { headers }) => {
    const token = await auth.getAccessTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
      authLink,
      retryLink,
      removeTypenameLink,
      httpLink,
    ]),
  });

  return (
    <ApolloProvider client={client}>
      <RouterProvider
        context={{ auth }}
        router={router}
      />
    </ApolloProvider>
  );
}

// Render the app
const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <Auth0Provider
        authorizationParams={{
          redirect_uri: process.env.REACT_APP_AUTH_CALLBACK_URL,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        }}
        clientId={process.env.REACT_APP_CLIENT_ID || ''}
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        onRedirectCallback={(appState) => {
          const returnTo = appState?.returnTo || window.location.origin;
          // If returnTo is not a valid domain, do nothing
          if (!returnTo.startsWith('http://localhost:') && !returnTo.endsWith('.creditpulse.com')) {
            return;
          }
          window.location.href = returnTo;
        }}
      >
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Auth0Provider>
    </StrictMode>,
  );
}
